import React, { useState, useEffect, Suspense } from 'react';

const Image = ({ src, alt }) => {
  return (
    <img src={src} alt={alt} />
  )
}

const LazyImage = ({ src, alt }) => {
  const [placeholder, setPlaceholder] = useState(null)
  const [image, setImage] = useState(null)

  useEffect(() => {
    let isMounted = true

    const generateBlur = (imageElement) => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = imageElement.width
      canvas.height = imageElement.height
      ctx.drawImage(imageElement, 0, 0)
      ctx.filter = 'blur(20px)'
      ctx.drawImage(canvas, 0, 0)
      return canvas.toDataURL()
    }

    const loadImage = async () => {
      const img = new window.Image()
      img.crossOrigin = "anonymous";
      img.onload = () => {
        if (isMounted) {
          setImage(img.src)
        }
      }
      img.src = src;
      img.width = 100;
      img.height = 100;
      setPlaceholder(generateBlur(img))
    }

    loadImage()

    return () => {
      isMounted = false
    }
  }, [src])

  return (
    <Suspense fallback={<Image src={placeholder} alt="Loading..." />}>
      {image && <img src={image} alt={alt} />}
    </Suspense>
  )
}

export default LazyImage
