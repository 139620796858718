import React from 'react'

import { styled } from '@mui/system';

const Modal = (props) => {
    const {
        open,
        handleClose
    } = props

    return open ? (
        <Root>
            {props.children}
        </Root>
    ) : <></>
}

const Root = styled('div')({
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    left: 0,
    top: 0,
    background: '#00000070',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999
})

export default Modal;