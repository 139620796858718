import React from 'react';
import { styled } from '@mui/system';
import { CircularProgress } from '@mui/material';

const Root = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw'
})

export default function Loader() {
  
  return (
    <Root>
      <CircularProgress />
    </Root>
  );
}
