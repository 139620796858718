import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, redirect, Routes, useRoutes } from "react-router-dom";
import LeftSideBar from "./components/LeftSideBar";
import SDKList from "./pages/SDkList";
import SDKStatistics from "./pages/SDKStatistics";
import Secrets from "./pages/Secrets";
// import Currencies from "./pages/Currencies";
import Contracts from "./pages/Contracts";
// import Transactions from "./pages/Transactions";
// import Settlement from "./pages/Settlement";
// import MethodDetails from "./pages/MethodDetails";
import GameDetails from "./pages/GameDetails";
import Header from "./components/Header";
import { useSelector } from "react-redux";
import LoginPage from "./pages/Login";
import AuthAxios from "./services/AuthAxios";
import Loader from "./components/Loader";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./App.css";
import Transactions from "./pages/Transactions";
import Settlements from "./pages/Settlements";
import Pricing from "./pages/Pricing";

// Protected Router for authentication checking
const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest}) => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("isAuthenticated", isAuthenticated)
    if (!isAuthenticated) {
      setLoading(true);
      AuthAxios.get("/dashboard/v1/verify-access-token")
      .then(res => {        
        setTimeout(() => {
          setLoading(false)
        }, 2000)
      })
      .catch(err => {
        setTimeout(() => {
          setLoading(false)
        }, 2000)
        localStorage.clear();
        navigate("/");
      })
    }
  }, [isAuthenticated])

  return (
    <>
    {
      isLoading ?
      <Loader /> :    
        <>
        <LeftSideBar />
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Header />
          <Component /> 
        </Box>
        </> 
    }
    </>
  )
}

const RouteElement = () => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  console.log("RouteElement", isAuthenticated)

  let routeElement = useRoutes([
    {
      path: "/",
      element: <LoginPage />
    },
    {
      path: "/sdk-list",
      element: <ProtectedRoute path="/sdk-list" isAuthenticated={isAuthenticated} component={SDKList} /> 
    },
    {
      path: "/sdk-statistics",
      element: <ProtectedRoute path="/sdk-statistics" isAuthenticated={isAuthenticated} component={SDKStatistics} />
    },
    {
      path: "/game-details",
      element: <ProtectedRoute path="/game-details" isAuthenticated={isAuthenticated} component={GameDetails} />
    },
    {
      path: "/secrets",
      element: <ProtectedRoute path="/secrets" isAuthenticated={isAuthenticated} component={Secrets} />
    },
    {
      path: "/contracts",
      element: <ProtectedRoute path="/contracts" isAuthenticated={isAuthenticated} component={Contracts} />
    },
    {
      path: "/transactions",
      element: <ProtectedRoute path="/transactions" isAuthenticated={isAuthenticated} component={Transactions} />
    },
    {
      path: "/settlements",
      element: <ProtectedRoute path="/settlements" isAuthenticated={isAuthenticated} component={Settlements} />
    },
    {
      path: "/pricing",
      element: <ProtectedRoute path="/pricing" isAuthenticated={isAuthenticated} component={Pricing} />
    }
  ])

  return routeElement;
}
        
function App() {
  return (
     <Box display="flex" flexDirection="row" width="100%" height={1}>  
        <Router>
          <RouteElement />
        </Router>
     </Box>
  );
}

export default App;
