import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Container, Typography, Button, Box, IconButton,  } from '@mui/material';
import Modal from '../components/Modal';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { BASE_URL } from '../constants/env';

import { styled } from '@mui/system';

const Secrets = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const auth = useSelector(state => state.auth);

    const [rows, setRows] = useState([]);
    const [newGenerated, setNewGenerated] = useState(null);

    useEffect(() => {
        axios.get(`${BASE_URL}/dashboard/v1/secrets-list`, {
            "headers": {
                'Authorization': 'Bearer ' + auth.token, 
                'Content-Type': 'application/json'
            }
        }).then(res => {
            console.log(res)
            setRows(res.data)
        }).catch(err => {
            console.log(err)
        })
    }, [newGenerated])

    return (
        <Container>
        <Box display="flex" flexDirection="column" paddingLeft="40px" paddingRight="40px">
            <ClientBox display="flex" flexDirection="column" width="fit-content">
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                    <ClientBoxTitle style={{marginTop: 0, marginBottom: 0}}>Client ID </ClientBoxTitle>
                    <span>:</span>
                    <ClientBoxInfo style={{marginTop: 0, marginBottom: 0}}>{auth.user.client_id}</ClientBoxInfo>
                </Box>
            </ClientBox>

            <Box display="flex" justifyContent="flex-end">
                <ActionButton onClick={() => {
                    axios.post(`${BASE_URL}/dashboard/v1/keygen`, {
                        "email": auth?.user?.email
                    }, {
                        headers: {
                            'Authorization': 'Bearer ' + auth.token, 
                            'Content-Type': 'application/json'
                        }
                    }).then(res => {
                        setNewGenerated(res?.data?.data[0])
                    }).catch(err => {
                        console.log(err)
                    })
                }}>Generate New Token</ActionButton>
            </Box>

            <Box>

            <TableContainer>
                    <CustomTable sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Secret Name</TableCell>
                                <TableCell>Created Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, key) => (
                                <TableRow
                                    key={key}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    onClick={() => {
                                        setOpen(key);
                                    }}
                                >
                                    <TableCell component="th" scope="row">{row.secretName}</TableCell>
                                    <TableCell>{new Date(row.createdAt).toDateString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </CustomTable>
                </TableContainer>
            </Box>

            <Modal open={open !== false}>
                <ModalWrapper>
                    <ModalTitleBar>
                        <button onClick={() => setOpen(false)}>x</button>
                    </ModalTitleBar>

                    <Box display="flex" flexDirection="column" justifyContent="center" width="100%">
                        <ModalDetails display="flex" flexDirection="row" width="100%" pt={0} mt={0}>
                            <ModalBoxTitle>Access Token </ModalBoxTitle>
                            <span>:</span>
                            <ModalBoxInfo>hfs427gg47-hsgs58-jsgsf-hsfs4572-762fs8765-hfs427gg47-hsgs58-jsgsf-hsfs4572-762fs8765-hfs427gg47-hsgs58-jsgsf-hsfs4572-762fs8765hfs427gg47-hsgs58</ModalBoxInfo>
                        </ModalDetails>
                        <ModalDetails display="flex" flexDirection="row" width="100%">
                            <ModalBoxTitle>Secret Name </ModalBoxTitle>
                            <span>:</span>
                            <ModalBoxInfo>{rows[open]?.secretName}</ModalBoxInfo>
                        </ModalDetails>
                        <ModalDetails display="flex" flexDirection="row" width="100%">
                            <ModalBoxTitle>Secret </ModalBoxTitle>
                            <span>:</span>
                            <ModalBoxInfo>{rows[open]?.secret}</ModalBoxInfo>
                        </ModalDetails>
                        <ModalDetails display="flex" flexDirection="row" width="100%" pb={0} style={{borderBottom: 'none'}}>
                            <ModalBoxTitle>Created at </ModalBoxTitle>
                            <span>:</span>
                            <ModalBoxInfo>{new Date(rows[open]?.createdAt).toDateString()}</ModalBoxInfo>
                        </ModalDetails>
                    </Box>
                </ModalWrapper>
            </Modal>

            <Modal open={newGenerated !== null}>
                <ModalWrapper>
                    <ModalTitleBar>
                        <button onClick={() => setNewGenerated(null)}>x</button>
                    </ModalTitleBar>

                    <Box display="flex" flexDirection="column" justifyContent="center" width="100%">
                        <ModalDetails display="flex" flexDirection="row" width="100%" mt={0} pt={0}>
                            <ModalBoxTitle>Access Token </ModalBoxTitle>
                            <span>:</span>
                            <ModalBoxInfo style={{}}>hfs427gg47-hsgs58-jsgsf-hsfs4572-762fs8765-hfs427gg47-hsgs58-jsgsf-hsfs4572-762fs8765-hfs427gg47-hsgs58-jsgsf-hsfs4572-762fs8765hfs427gg47-hsgs58</ModalBoxInfo>
                        </ModalDetails>
                        <ModalDetails display="flex" flexDirection="row" width="100%" mt={0}>
                            <ModalBoxTitle>Secret Name </ModalBoxTitle>
                            <span>:</span>
                            <ModalBoxInfo >{newGenerated?.secret_name}</ModalBoxInfo>
                        </ModalDetails>
                        <ModalDetails display="flex" flexDirection="row" alignItems="center" width="100%" mt={0}>
                            <ModalBoxTitle>Secret </ModalBoxTitle>
                            <span>:</span>
                            <ModalBoxInfo style={{overflow: "hidden", textOverflow: "ellipsis"}}>{newGenerated?.secret}</ModalBoxInfo>
                            <IconButton onClick={() => { 
                                navigator.clipboard.writeText(newGenerated?.secret) 
                                toast("Copied to clipboard")
                            }}>
                                Copy
                                {/* <BookIcon /> */}
                            </IconButton>
                        </ModalDetails>
                        <ModalDetails display="flex" flexDirection="row" width="100%" pb={0} mt={0} style={{borderBottom: 'none'}} >
                            <ModalBoxTitle>Created at </ModalBoxTitle>
                            <span>:</span>
                            <ModalBoxInfo>{new Date().toDateString()}</ModalBoxInfo>
                        </ModalDetails>
                    </Box>
                </ModalWrapper>
            </Modal>
        </Box>

        <Toaster />
        </Container>
    )
}

const CustomTable = styled(Table)({
    border: '1px solid #E3E3E3',
    borderRadius: '2px',
    '& tbody>tr': {
        '&:hover': {
            background: '#e3e3e3',
            cursor: 'pointer'
        }
    },
    '& thead th': {
        fontWeight: 700,
        padding: '8px 16px',
        borderRadius: '2px',
        border: '1px solid #E3E3E3'
    },
    '& tbody td': {
        padding: '12px 16px',
        border: '1px solid #E3E3E3 !important'
    }
})

const ClientBox = styled(Box)({
    border: '1px solid #E3E3E3',
    borderRadius: '8px',
    padding: '16px',
    '& p': {
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 'normal',
    },
    '& span': {
        marginRight: '24px'
    }
})

const ClientBoxTitle = styled('p')({
    width: "174px"
})

const ClientBoxInfo = styled('p')({
    fontWeight: '700 !important'
})

const ClientBoxMarginTopZero = styled(Box)({
    '& p': {
        marginTop: 0
    }
})

const ActionButton = styled(Button)({
    height: '24px',
    background: '#6D00DB',
    fontSize: '16px',
    padding: '12px 32px',
    boxSizing: 'content-box',
    color: '#FFFFFF',
    borderRadius: '25px',
    marginBottom: '16px',
    '&:hover': {
        background: '#501989'
    }
})

const ModalWrapper = styled(Box)({
    background: 'white',
    width: "970px",
    minHeight: "288px",
    padding: '0px 22.3px 24px 22.3px',
    boxSizing: 'border-box',
    borderRadius: '8px',
    overflow: 'auto'
})

const ModalTitleBar = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    height: 48,
    '& button': {
        background: 'none',
        outline: 'unset',
        boxShadow: 'unset',
        border: 0,
        '&:hover': {
            cursor: 'pointer'
        }
    }
})

const ModalDetails = styled(Box)({
    width: '100%',
    borderBottom: '1px solid #E3E3E3',
    paddingTop: '16px',
    paddingBottom: '16px',
    alignItems: 'center',
    '& >p': {
        marginTop: 0,
        marginBottom: 0
    }
})

const ModalBoxTitle = styled('p')({
    minWidth: '120px',
    paddingRight: '24px',
    color: '#828282',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    boxSizing: 'content-box'
})

const ModalBoxInfo = styled('p')({
    marginLeft: '24px',
    fontSize: '16px',
    color: '#282828',
    fontWeight: 600,
    lineHeight: '24px'
})


export default Secrets