import { Box, Button, Typography } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'

const Pagination = () => {
    return (
        <PaginationWrapper>
            <PaginationStatus>
                Showing 1 to 10 of 98 entries
            </PaginationStatus>
            <PaginationRoot display="flex" flexDirection="row" >
                <Button>First</Button>
                <Button>Prev</Button>
                <NumButton>1</NumButton>
                <NumButton>2</NumButton>
                <NumButton>3</NumButton>
                <NumButton>4</NumButton>
                <NumButton>5</NumButton>
                <Button>Next</Button>
                <Button>Last</Button>
            </PaginationRoot>
        </PaginationWrapper>
    )
}

const PaginationWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
    alignItems: 'center',
})

const PaginationStatus = styled(Typography)({
    color: '#828282',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
})

const PaginationRoot = styled(Box)({
    border: '1px solid #E2E2E2',
    '& button': {
        fontSize: '14px',
        color: '#282828',
        fontWeight: 500,
        height: 30
    }
})

const NumButton = styled(Button)({
    minWidth: 'unset',
    height: 30,
    width: 30,
    fontWeight: '500'
})

export default Pagination;