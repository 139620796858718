import { Box } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";

const Header = () => {
    const auth = useSelector(state => state.auth);

    console.log("auth", auth)
    
    return (
        <Root>
            <Box marginTop="41px" paddingRight="41px">
                {auth?.user?.email}
            </Box>
        </Root>
    )
}

const Root = styled(Box)({
    width: '100%',
    height: 82,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
})

export default Header