import web3 from 'web3';

export const convertShortenedWeb3Address = (walletAddress) => {
    return web3.utils.toChecksumAddress(walletAddress).substring(0, 6) + '...' + web3.utils.toChecksumAddress(walletAddress).substring(38)
}

export const displayMinusWhenUnavailable = (value) => {
    return value ? value : "-";
}

export const displayBlankWhenUnavailable = (value) => {
    return value ? value : "";
}