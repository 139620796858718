import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';

import { Provider } from "react-redux";
import { configureStore, applyMiddleware } from '@reduxjs/toolkit';
import thunk from "redux-thunk";

import { rootReducer } from './redux/reducers';

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

store.subscribe(() => {
  const { auth } = store.getState();
  const { token, user } = auth;

  console.log("store.subscribe", auth)

  localStorage.setItem('access_token', token)
  localStorage.setItem('user_info', JSON.stringify(user))
})

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Provider>,
);
