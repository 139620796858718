import { Button, Container, Table, Typography, TableHead, TableBody, TableCell, TableRow, CircularProgress } from '@mui/material';
import { Box, styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import Pagination from '../components/Pagination';
import { convertShortenedWeb3Address, displayMinusWhenUnavailable } from '../utils';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../constants/env';
import { tokenList } from '../constants/tokenList';

const Transactions = () => {
    const [showDetails, setShowDetails] = React.useState(false);
    const [rows, setRows] = useState([]);
    const [detailedData, setDetailedData] = useState();
    const [isLoading, setLoading] = useState(false);
    const auth = useSelector(state => state.auth);    

    useEffect(() => {
        if (!isLoading) {
            setLoading(true)
            axios.get(`${BASE_URL}/dashboard/v1/get-market-history-list`, {
                headers: {
                    'Authorization': 'Bearer ' + auth.token, 
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                setLoading(false)
                console.log(res)
                setRows(res.data.data[0]);
            })
            .catch(err => {
                setLoading(false)
                console.error(err)
            })
        }
    }, [])

    useEffect(() => {

        
        if (showDetails !== false) {
            console.log(showDetails)
            console.log("Loading Details..............")
            if (isLoading !== true) {
                console.log("Loading Details..............", showDetails, isLoading)
                setLoading(true)

                let data = JSON.stringify({
                    payment_event_id: rows[showDetails].paymentEventId,
                    game_asset_id: rows[showDetails].gameAssetId,
                    game_instance_id: rows[showDetails].gameInstanceId,
                    sender_user_id: rows[showDetails].sellerUserId,
                    receiver_user_id: rows[showDetails].receiverUserId,
                  });
                  
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: `${BASE_URL}/dashboard/v1/get-market-history-details`,
                    headers: { 
                        'Content-Type': 'application/json', 
                        'Authorization': `Bearer ${auth.token}`
                    },
                    data : data
                };

                axios.request(config)
                .then((response) => {
                    console.log(response)
                    setDetailedData(response.data.data[0])
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
            }
        }
    }, [showDetails])

    return (
        <PageWrapper>
            {
                showDetails === false ?
                isLoading ?
                <Typography align="center" variant="h6">Loading...</Typography> :
                <>    
                    <PageHeader>Transactions</PageHeader>

                    <TransactionTableWrapper>
                        <TransactionTable>
                        <TableHead>
                            <TableRow>
                                <TableCell width="19.5%">Transaction ID</TableCell>
                                <TableCell width="19.5%">Transaction Date</TableCell>
                                <TableCell width="19.5%">Seller</TableCell>
                                <TableCell width="19.5%">Buyer</TableCell>
                                <TableCell width="21%">Total Price</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, key) => (
                                    <TableRow
                                        
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">{row.paymentEventId}</TableCell>
                                        <TableCell>{new Date(row.transactionDate).toDateString()}</TableCell>
                                        <TableCell>
                                            {row.sellerUserId}
                                        </TableCell>
                                        <TableCell>
                                            {row.receiverUserId}
                                        </TableCell>
                                        <TableCell>
                                            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                                {row.totalPrice} {row.currency}

                                                <ShowDetailsButton onClick={() => setShowDetails(key)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M16.8006 12.0005C16.8006 12.7005 16.5306 13.4005 16.0006 13.9305L9.48062 20.4505C9.19062 20.7405 8.71062 20.7405 8.42063 20.4505C8.13063 20.1605 8.13063 19.6805 8.42063 19.3905L14.9406 12.8705C15.4206 12.3905 15.4206 11.6105 14.9406 11.1305L8.42063 4.6105C8.13063 4.3205 8.13063 3.8405 8.42063 3.5505C8.71062 3.2605 9.19062 3.2605 9.48062 3.5505L16.0006 10.0705C16.5306 10.6005 16.8006 11.3005 16.8006 12.0005Z" fill="#0652DD"/></svg>
                                                </ShowDetailsButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </TransactionTable>
                    </TransactionTableWrapper>

                    <Pagination />
                </> :
                <>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <GoBackButton onClick={() => setShowDetails(false)}><svg xmlns="http://www.w3.org/2000/svg" width="9" height="18" viewBox="0 0 9 18" fill="none"><g clipPath="url(#clip0_407_88)"><path d="M7.9993 17.67C7.8093 17.67 7.6193 17.6 7.4693 17.45L0.949297 10.93C-0.110703 9.87 -0.110703 8.13 0.949297 7.07L7.4693 0.55002C7.7593 0.26002 8.2393 0.26002 8.5293 0.55002C8.8193 0.84002 8.8193 1.32002 8.5293 1.61002L2.0093 8.13C1.5293 8.61 1.5293 9.39 2.0093 9.87L8.5293 16.39C8.8193 16.68 8.8193 17.16 8.5293 17.45C8.3793 17.59 8.1893 17.67 7.9993 17.67Z" fill="#828282"/></g><defs><clipPath id="clip0_407_88"><rect width="9" height="18" fill="white"/></clipPath></defs></svg></GoBackButton>
                        <GoBackText>Back</GoBackText>
                    </Box>

                    <Box marginTop="20px" marginBottom="20px" display="flex" flexDirection="row" alignItems="center">
                        <TransactionDetailsText>Transaction Details</TransactionDetailsText>
                        <TxIDText>Txn ID # {displayMinusWhenUnavailable(detailedData?.details?.fireblocksTxId)}</TxIDText>
                    </Box>

                    <Box display="flex" flexDirection="row">
                        <TxDetailsWrapper>
                            <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                                <TxDetailsFieldText>Product ID</TxDetailsFieldText>
                                <TxDetailsValueText>{
                                    !isLoading ?
                                    detailedData?.details?.productId :
                                    <CircularProgress size={15} />
                                }</TxDetailsValueText>
                            </Box>

                            <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                                <TxDetailsFieldText>Token ID</TxDetailsFieldText>
                                <TxDetailsValueText>{
                                    !isLoading ?
                                    detailedData?.details?.tokenId :
                                    <CircularProgress size={15} />
                            }</TxDetailsValueText>
                            </Box>

                            <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                                <TxDetailsFieldText>Created At</TxDetailsFieldText>
                                <TxDetailsValueText>{
                                    !isLoading ?
                                    new Date(rows[showDetails]?.transactionDate).toDateString() : 
                                    <CircularProgress size={15} />
                                    }</TxDetailsValueText>
                            </Box>

                            <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                                <TxDetailsFieldText>Completed At</TxDetailsFieldText>
                                <TxDetailsValueText>{
                                    !isLoading ?
                                    new Date(rows[showDetails]?.transactionDate).toDateString() :
                                    <CircularProgress size={15} />
                                }</TxDetailsValueText>
                            </Box>

                            <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                                <TxDetailsFieldText>Item Name</TxDetailsFieldText>
                                <TxDetailsValueText>{
                                    !isLoading ?
                                    detailedData?.details?.itemName :
                                    <CircularProgress size={15} />
                            }</TxDetailsValueText>
                            </Box>

                            <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                                <TxDetailsFieldText>Game Name</TxDetailsFieldText>
                                <TxDetailsValueText>{
                                    !isLoading ?
                                    detailedData?.details?.gameName :
                                    <CircularProgress size={15} />
                            }</TxDetailsValueText>
                            </Box>

                            <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                                <TxDetailsFieldText>Seller Email</TxDetailsFieldText>
                                <TxDetailsValueText>{
                                    !isLoading ?
                                    detailedData?.details?.sellerEmailId :
                                    <CircularProgress size={15} />
                            }</TxDetailsValueText>
                            </Box>

                            <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                                <TxDetailsFieldText>Seller web3 address</TxDetailsFieldText>
                                <TxDetailsValueText>{
                                    !isLoading ?
                                    detailedData?.details?.sellerWeb3Address :
                                    <CircularProgress size={15} />
                                }</TxDetailsValueText>                                        
                            </Box>

                            <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                                <TxDetailsFieldText>Buyer Email</TxDetailsFieldText>
                                <TxDetailsValueText>{
                                    !isLoading ?
                                    detailedData?.details?.buyerEmailId :
                                    <CircularProgress size={15} />
                            }</TxDetailsValueText>
                            </Box>

                            <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                                <TxDetailsFieldText>Buyer web3 address</TxDetailsFieldText>
                                <TxDetailsValueText>{
                                    !isLoading ?
                                    detailedData?.details?.buyerWeb3Address:
                                    <CircularProgress size={15} />
                                }</TxDetailsValueText>
                            </Box>

                            <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                                <TxDetailsFieldText minWidth="200px">Blockchain Tx Hash</TxDetailsFieldText>
                                <TxDetailsValueText>
                                    {
                                        !isLoading ?
                                        detailedData?.details?.txHash :
                                        <CircularProgress size={15} />
                                    }
                                </TxDetailsValueText>
                            </Box>
                        </TxDetailsWrapper>

                        <PaymentSummaryWrapper>
                            <Box paddingBottom="16px" marginBottom="16px" style={{borderBottom: '1px solid #E3E3E3'}}>
                                <PaymentSummaryHeading>Payment Summary</PaymentSummaryHeading>
                                <Box marginTop="24px" display="flex" width="100%" flexDirection="row" justifyContent="space-between">
                                    <SummaryKey>Item Price</SummaryKey>
                                    <SummaryValue>
                                        {
                                            isLoading ?
                                            <CircularProgress size={15} /> :
                                            <>{displayMinusWhenUnavailable(detailedData?.payment?.itemPrice)} {rows[showDetails]?.currency}</>
                                        }
                                    </SummaryValue>
                                </Box>
                                <Box marginTop="12px" display="flex" width="100%" flexDirection="row" justifyContent="space-between">
                                    <SummaryKey>Seller Share</SummaryKey>
                                    <SummaryValue>
                                        {
                                            isLoading ?
                                            <CircularProgress size={15} /> :
                                            <>{displayMinusWhenUnavailable(detailedData?.payment?.sellerPrice)} {rows[showDetails]?.currency}</>
                                        }
                                    </SummaryValue>
                                </Box>
                                <Box marginTop="12px" display="flex" width="100%" flexDirection="row" justifyContent="space-between">
                                    <SummaryKey>Platform Fees</SummaryKey>
                                    <SummaryValue>
                                        {
                                            isLoading ?
                                            <CircularProgress size={15} /> :
                                            <>{displayMinusWhenUnavailable(detailedData?.payment?.platformFee)} {rows[showDetails]?.currency}</>
                                        }
                                    </SummaryValue>
                                </Box>
                                <Box marginTop="12px" display="flex" width="100%" flexDirection="row" justifyContent="space-between">
                                    <SummaryKey>Supaki Share</SummaryKey>
                                    <SummaryValue>
                                        {
                                            isLoading ?
                                            <CircularProgress size={15} /> :
                                            <>{displayMinusWhenUnavailable(detailedData?.payment?.supakiShare)} {rows[showDetails]?.currency}</>
                                        }
                                    </SummaryValue>
                                </Box>
                                <Box marginTop="12px" display="flex" width="100%" flexDirection="row" justifyContent="space-between">
                                    <SummaryKey>Game dev Share</SummaryKey>
                                    <SummaryValue>
                                        {
                                            isLoading ?
                                            <CircularProgress size={15} /> :
                                            <>{displayMinusWhenUnavailable(detailedData?.payment?.gameDevShare.amount)} {rows[showDetails]?.currency}</>
                                        }
                                    </SummaryValue>
                                </Box>
                                <Box marginTop="12px" display="flex" width="100%" flexDirection="row" justifyContent="space-between">
                                    <SummaryKey>Blockchain Gas Fees</SummaryKey>
                                    <SummaryValue>
                                        {
                                            isLoading ?
                                            <CircularProgress size={15} /> :
                                            <>{displayMinusWhenUnavailable(detailedData?.payment?.blockchainGasFee)} {tokenList[detailedData?.payment?.gasFeeAssetId]}</>
                                        }
                                    </SummaryValue>
                                </Box>
                            </Box>
                            {/* <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" maxHeight="56px">
                                <SumUpField>
                                    Total Amount
                                </SumUpField>
                                <Box display="flex" flexDirection="column">
                                    <SumUpResultUSDC>- USDC</SumUpResultUSDC>
                                    <SumUpResultIDR>({} IDR)</SumUpResultIDR>
                                </Box>
                            </Box> */}
                        </PaymentSummaryWrapper>
                    </Box>
                </>
            }
        </PageWrapper>
    )
}

const PageWrapper = styled(Container)({
    padding: '25px 40px'
})

const PageHeader = styled(Typography)({
    color: '#282828',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
})

const TransactionTableWrapper = styled(`div`)({
    marginTop: '24px'
})

const TransactionTable = styled(Table)({
    border: '1px solid #E3E3E3',
    borderRadius: '2px',
    '& thead th': {
        fontWeight: 700,
        padding: '8px 16px',
        borderRadius: '2px',
        border: '1px solid #E3E3E3',
        background: '#F9F9F9',
        color: '#828282'
    },
    '& tbody td': {
        padding: '12px 16px',
        border: '1px solid #E3E3E3 !important',
        overflow: 'hidden'
    }
})

const ShowDetailsButton = styled(Button)({
    '&:hover': {
        background: 'none',
        color: 'black'
    }
})


const GoBackButton = styled(Button)({
    padding: 0,
    minWidth: 0,
    marginRight: 16,
    '&:hover': {
        background: 'none'
    }
})

const GoBackText = styled(Typography)({
    fontSize: '14px',
    fontStyle: 700,
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '-0.4px',
    color: '#828282'
})

const TransactionDetailsText = styled(Typography)({
    color: '#282828',
    fontSize: '20px',
    fontStyle: 700,
    fontWeight: 600,
    lineHeight: '24px',
    paddingRight: '16px',
    borderRight: '1px solid #828282'
})

const TxIDText = styled(Typography)({
    color: '#828282',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    paddingLeft: '16px'
})

const TxDetailsWrapper = styled(Box)({
    display: 'flex',
    width: '725px',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    background: '#F9F9F9'
})

const TxDetailsFieldText = styled(Typography)({
    color: '#828282',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
})

const TxDetailsValueText = styled(Typography)({
    color: '#282828',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    overflow: 'hidden',
    textOverflow:'ellipsis',
    whiteSpace: 'nowrap'
})

const PaymentSummaryWrapper = styled(`div`)({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '32px',
    flexGrow: 1,
    marginRight: '40px',
})

const PaymentSummaryHeading = styled(Typography)({
    color: '#282828',
    fontSize: '16px',
    fontStyle: 700,
    fontWeight: 600,
    lineHeight: '20px'
})

const SummaryKey = styled(Typography)({
    color: '#828282',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
})

const SummaryValue = styled(Typography)({
    color: '#282828',
    textAlign: 'right',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
})

const SumUpField = styled(Typography)({
    color: '#282828',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px'
})

const SumUpResultUSDC = styled(Typography)({
    color: '#282828',
    textAlign: 'right',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '36px',
})

const SumUpResultIDR = styled(Typography)({
    color: '#828282',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
})

export default Transactions;