import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Input, InputBase, Container, Select, FormControl, MenuItem, InputLabel } from '@mui/material';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import React from 'react';
import Modal from '../components/Modal';
import axios from 'axios';
import { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../constants/env';
import web3 from 'web3';
import { styled } from '@mui/system';




const SelectInput = styled(InputBase)(({theme}) => ({
    '&.MuiInputBase-root': {
        flexGrow: 1,
    },
    '& .MuiInputBase-input': {
        borderRadius: '50px',
        border: '1px solid #E3E3E3',
        flexGrow: 1,
        height: '62px !important',
        boxSizing: 'border-box !important',
        padding: '29px 10px 0px 20px !important',
        fontSize: '14px',
        fontWeight: 400,
        '&:focus': {
            borderRadius: '50px',
            border: '1px solid grey',
            flexGrow: 1,
        }
    }
})) 

const Contracts = () => {
    const [open, setOpen] = React.useState(false);
    const [gameInstance, setGameInstance] = React.useState("");
    const [rows, setRows] = React.useState([]);

    const auth = useSelector(state => state.auth);

    const chainIds = {
        "ETHEREUM": 1,
        "POLYGON": 137,
        "SOLANA": 101,
        "AVALANCHE": 43114
    }

    const [gameContractParam, setGameContractParam] = React.useState({
        contractAddress: "",
        abi: [],
        chainId: 1,
        gameInstanceId: gameInstance,
        platform: "ETHEREUM",
        tokenType: "ERC20"
    })

    const notify = () => toast('Contract added successfully.');

    useEffect(() => {
        setGameContractParam({
            ...gameContractParam,
            gameInstanceId: gameInstance
        })
        if (gameInstance != "") {
            axios.post(`${BASE_URL}/dashboard/v1/contracts-list`, {
                "gameInstanceId": gameInstance
            }, {
                headers: {
                    'Authorization': 'Bearer ' + auth.token, 
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                setRows(res.data)
            }).catch(err => {
                console.log(err)
            })
        }
    }, [gameInstance])

    const handleChange = (key, val) => {
        if (key == "platform") {
            console.log({
                ...gameContractParam,
                ["chainId"]: chainIds[val]
            })
            setGameContractParam({
                ...gameContractParam,
                ["chainId"]: chainIds[val],
                [key]: val
            })
        } else {
            setGameContractParam({
                ...gameContractParam,
                [key]: val
            })
        }

    }

    return (
        <Container>
        <Box display="flex" flexDirection="column" paddingLeft="40px" paddingRight="40px">
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" mb="24px">
                <PageTitle>Contracts</PageTitle>
                <ActionButton disabled={gameInstance == ""} onClick={() => setOpen(true)}>Add Contract</ActionButton>
            </Box>

            <Box>
                <p>Game instance ID</p>
                <FormControl sx={{ m: 1, minWidth: 363, minHeight: 65 }} >
                    <SelectLabel id="demo-simple-select-helper-label">Game Name</SelectLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={gameInstance}
                        label="Game Name"
                        onChange={(e) => {
                            console.log(e.target.value)
                            setGameInstance(e.target.value)
                        }}
                        input={<SelectInput />}
                    >
                        {
                            auth?.user?.game_instance_ids?.map((id, key) => <MenuItem key={key} value={id}>{id}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </Box>

            {
                rows.length > 0 ?

            <Box>
                <TableContainer>
                    <CustomTable sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Address</TableCell>
                                <TableCell>ABI</TableCell>
                                <TableCell>Token Type</TableCell>
                                <TableCell>Platform</TableCell>
                                <TableCell>Chain ID</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map((row, key) => (
                                <TableRow
                                    key={key}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{row.id}</TableCell>
                                    <TableCell>{JSON.stringify(row.abi).slice(0, 40)}</TableCell>
                                    <TableCell>{row.tokenType}</TableCell>
                                    <TableCell>{row.platform}</TableCell>
                                    <TableCell>{row.chainId}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </CustomTable>
                </TableContainer>

                {/* <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop="20px">
                    <Typography>Showing 1 to 10 of 98 entries</Typography>
                    <Pagination />
                </Box> */}
            </Box>
            :
            <>
                <Typography style={{textAlign: 'center', marginTop: 10}}>No data available</Typography>
            </>
            }

            <Modal open={open}>
                <ModalWrapper>
                    <ModalTitleBar>
                        <button onClick={() => setOpen(false)}>x</button>
                    </ModalTitleBar>

                    <Box display="flex" flexDirection="column" justifyContent="center" width="100%" pt="10px">
                        <ModalTitle>Add Contract</ModalTitle>
                    </Box>

                    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" mt="32px" gridGap="16px">    
                        <Box display="flex" flexDirection="column" width="100%">
                            <ModalLabel>Game Instance ID</ModalLabel>
                            <ModalInputWrapper disabled={true} value={gameInstance}/>
                        </Box>

                        <Box display="flex" flexDirection="column" width="100%">
                            <ModalLabel>Address</ModalLabel>
                            <ModalInputWrapper value={gameContractParam.contractAddress} onChange={e => handleChange("contractAddress", e.target.value)} />
                        </Box>
                    </Box>

                    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" mt="32px" gridGap="16px">    
                        <Box display="flex" flexDirection="column" width="100%">
                            <ModalLabel>Blockchain Platform</ModalLabel>
                            <Select
                                value={gameContractParam.platform}
                                onChange={(e) => handleChange('platform', e.target.value)}
                                style={{borderRadius: 50}}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                >
                                <MenuItem value="ETHEREUM">ETHEREUM</MenuItem>
                                <MenuItem value="POLYGON">POLYGON</MenuItem>
                                <MenuItem value="SOLANA">SOLANA</MenuItem>
                                <MenuItem value="AVALANCHE">AVALANCHE</MenuItem>
                            </Select>
                        </Box>

                        <Box display="flex" flexDirection="column" width="100%">
                            <ModalLabel>Token Type</ModalLabel>
                            <Select
                                value={gameContractParam.tokenType}
                                onChange={e => handleChange("tokenType", e.target.value)}
                                style={{borderRadius: 50}}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                >
                                <MenuItem value="ERC20">ERC20</MenuItem>
                                <MenuItem value="ERC721">ERC721</MenuItem>
                                <MenuItem value="ERC1155">ERC1155</MenuItem>
                            </Select>
                        </Box>
                    </Box>

                    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" mt="32px" gridGap="16px">    
                        <Box display="flex" flexDirection="column" width="100%">
                            <ModalLabel>ABI</ModalLabel>
                            <ModalInputWrapper disabled={gameContractParam.tokenType == "ERC20"} value={gameContractParam.abi} onChange={e => handleChange("abi", e.target.value)} />
                        </Box>

                        <Box display="flex" flexDirection="column" width="100%">
                            <ModalLabel>Chain ID</ModalLabel>
                            <ModalInputWrapper disabled={true} value={gameContractParam.chainId} onChange={e => handleChange("chainId", e.target.value)} />
                        </Box>
                    </Box>


                    <Box mt="8px">
                        <ActionButton onClick={() => {
                            if (gameContractParam.abi == "" && gameContractParam.tokenType != "ERC20") {
                                toast("You must input abi!")
                            } else if (!web3.utils.isAddress(gameContractParam.contractAddress, gameContractParam.chainId)) {
                                toast("Contract address is not valid!")
                            } else {
                                axios.post(`${BASE_URL}/dashboard/v1/add-game-contract`, gameContractParam, {
                                    headers: {
                                        'Authorization': 'Bearer ' + auth.token, 
                                        'Content-Type': 'application/json'
                                    }
                                }).then(res => {
                                    axios.post(`${BASE_URL}/dashboard/v1/contracts-list`, {
                                        "gameInstanceId": gameInstance
                                    }, {
                                        headers: {
                                            'Authorization': 'Bearer ' + auth.token, 
                                            'Content-Type': 'application/json'
                                        }
                                    }).then(res => {
                                        setRows(res.data)
                                        notify();
                                        setOpen(false)
                                    }).catch(err => {
                                        console.log(err)
                                    })
                                }).catch(err => {
                                    console.log(err);
                                    toast(err?.response?.data?.message);
                                    // setOpen(false)
                                })
                            }
                        }}>Add Contract</ActionButton>
                    </Box>
                </ModalWrapper>
            </Modal>

            <Toaster />
        </Box>
        </Container>
    )
}

const PageTitle = styled(Typography)({
    marginBottom: '24px',
    fontWeight: "700",
    fontSize: '20px !important'
})

const CustomTable = styled(Table)({
    border: '1px solid #E3E3E3',
    borderRadius: '2px',
    '& thead th': {
        fontWeight: 700,
        padding: '8px 16px',
        borderRadius: '2px',
        border: '1px solid #E3E3E3 !important'
    },
    '& tbody td': {
        padding: '12px 16px',
        border: '1px solid #E3E3E3 !important'
    }
})

const ActionButton = styled(Button)({
    height: '24px',
    background: '#6D00DB',
    fontSize: '16px',
    padding: '12px 32px',
    boxSizing: 'content-box',
    color: '#FFFFFF',
    borderRadius: '25px',
    marginBottom: '16px',
    textTransform: 'none',
    '&:hover': {
        background: '#501989'
    }
})

const ModalWrapper = styled(Box)({
    background: 'white',
    width: "798px",
    padding: '24px',
    boxSizing: 'border-box',
    borderRadius: '8px'
})

const ModalTitleBar = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    paddingTop: 14,
    height: 6,
    '& button': {
        background: 'none',
        outline: 'unset',
        boxShadow: 'unset',
        border: 0,
        '&:hover': {
            cursor: 'pointer'
        }
    }
})

const ModalTitle = styled(Box)({
    fontSize: '18px',
    color: '#282828',
    lineHeight: '24px',
    fontWeight: '600'
})

const ModalLabel = styled('label')({
    color: '#828282',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    fontSize: '14px'
})

const ModalInputWrapper = styled('input')({
    borderRadius: '25px',
    border: '1px solid #E3E3E3',
    boxSizing: 'border-box',
    background: '#FFFFFF',
    marginTop: 8,
    marginBottom: 16,
    padding: '10px 8px 10px 16px',
    '&:focus-visible': {
        borderColor: '#E3E3E3',
        outline: 'none'
    }
})

const SelectLabel = styled(InputLabel)({
    top: '-6px !important',
    left: '7px !important'
})

export default Contracts