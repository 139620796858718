import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Box, Button } from '@mui/material';

 const LeftSideBar = (props) => {
    const [currentUrl, setCurrentUrl] = useState("/sdk-list");

    const navInfoList = [
        {
            title: "SDK List",
            href: "/sdk-list",
            enabled: true
        },
        {
            title: "SDK Statistics",
            href: "/sdk-statistics",
            enabled: true
        },
        {
            title: "Game Details",
            href: "/game-details",
            enabled: true
        },
        {
            title: "Secrets",
            href: "/secrets",
            enabled: true
        },
        {
            title: "Currencies",
            href: "/currencies",
            enabled: false
        },
        {
            title: "Contracts",
            href: "/contracts",
            enabled: true
        },
        {
            title: "Transactions",
            href: "/transactions",
            enabled: true
        },
        {
            title: "Settlement",
            href: "/settlements",
            enabled: true
        },
        {
            title: "Pricing Details",
            href: "/pricing",
            enabled: true
        }
    ]

    return (
        <Root {...props}>
            
            <Box marginBottom="24px">
                <img src="assets/logo.png"/>
            </Box>
            <Box display="flex" flexDirection="column" flexGrow={1}>
                {
                    navInfoList.map((item, key) => <NavBtn disabled={!item.enabled} key={key} selected={item.href === window.location.pathname} href={item.href} onClick={() => {
                        setCurrentUrl(item.href)
                    }}>{item.title}</NavBtn>)
                }
            </Box>
        </Root>
    )
 }

 const Root = styled('div')({
     width: '240px',
     borderRight: '1px solid #E3E3E3',
     display: 'flex',
     flexDirection: 'column',
     height: 'calc(100vh - 35px)',
     paddingTop: 25.32,
     paddingLeft: 24,
     paddingBottom: 10
 })

const NavBtn = styled(Button)(props => ({
  width: '100%',
  color: '#282828',
  height: '56px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontFamily: 'Montserrat',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '24px',
  borderLeft: props.selected ? '4px solid #6D00DB' : 'none',
  background: props.selected ? '#FAF9FF' : 'unset',
  // Set text color with conditional statement
  color: props.selected ? '#6D00DB' : '#282828',
}));

  
    

 export default LeftSideBar