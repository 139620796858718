import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Container, Typography, Button, Box, Grid,  } from '@mui/material'
import React from 'react'
import Pagination from '../components/Pagination';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../constants/env';
import { useSelector } from 'react-redux';
import { fontWeight, styled } from '@mui/system';
import { displayBlankWhenUnavailable, displayMinusWhenUnavailable } from '../utils';

const SDKStatistics = () => {
    const [showDetails, setShowDetails] = useState(false);
    const [rows, setRows] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const auth = useSelector(state => state.auth);    

    useEffect(() => {
        if (!isLoading) {
            setLoading(true)
            axios.get(`${BASE_URL}/dashboard/v1/method-details-v2`, {
                headers: {
                    'Authorization': 'Bearer ' + auth.token, 
                    'Content-Type': 'application/json'
                }
            })
            .then(res => {
                setLoading(false)
                console.log(res)
                setRows(res.data.data[0]);
            })
            .catch(err => {
                setLoading(false)
                console.error(err)
            })
        }
    }, [])
   
    return (
        <>
            {
                isLoading ?
                <Typography align="center" variant="h6">Loading...</Typography> :
                rows.length > 0 ?
                <>
                    {
                    showDetails === false ?
                    <Container>
                        <PageTitle>SDK Statistics</PageTitle>
                        <TableContainer>
                            <CustomTable sx={{ minWidth: 650 }} game_id="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Ref ID</TableCell>
                                        <TableCell>Method Details</TableCell>
                                        <TableCell>Game ID</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, key) => (
                                        <TableRow
                                            key={key}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>{new Date(row.createdAt).toDateString()}</TableCell>
                                            <TableCell>{row.referenceId}</TableCell>
                                            <TableCell>{row.methodType}</TableCell>
                                            <TableCell>
                                                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                                    {row.gameName}
                                                    <DetailedButton onClick={() => setShowDetails(key)}>></DetailedButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </CustomTable>
                        </TableContainer>

                        {/* <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop="20px">
                            <Typography>Showing 1 to 10 of 98 entries</Typography>
                            <Pagination />
                        </Box> */}
                    </Container>
                    :
                    <DetailedWrapper padding="40px">
                         <Box display="flex" flexDirection="row" alignItems="center">
                            <GoBackButton onClick={() => setShowDetails(false)}><svg xmlns="http://www.w3.org/2000/svg" width="9" height="18" viewBox="0 0 9 18" fill="none"><g clipPath="url(#clip0_407_88)"><path d="M7.9993 17.67C7.8093 17.67 7.6193 17.6 7.4693 17.45L0.949297 10.93C-0.110703 9.87 -0.110703 8.13 0.949297 7.07L7.4693 0.55002C7.7593 0.26002 8.2393 0.26002 8.5293 0.55002C8.8193 0.84002 8.8193 1.32002 8.5293 1.61002L2.0093 8.13C1.5293 8.61 1.5293 9.39 2.0093 9.87L8.5293 16.39C8.8193 16.68 8.8193 17.16 8.5293 17.45C8.3793 17.59 8.1893 17.67 7.9993 17.67Z" fill="#828282"/></g><defs><clipPath id="clip0_407_88"><rect width="9" height="18" fill="white"/></clipPath></defs></svg></GoBackButton>
                            <GoBackText>Back</GoBackText>
                        </Box>

                        <PageTitle>Method Details</PageTitle>

                        <OperationStatus>{displayBlankWhenUnavailable(rows[showDetails]?.status)}</OperationStatus>

                        <BgGrey marginTop="16px" marginBottom="32px" padding="24px">
                        <Grid container>
                            <Grid Item xs={12} sm={4}>
                                <Box display="flex" flexDirection="column">
                                <BgGrey>Date created</BgGrey>
                                <OverflowHiddenText>{new Date(rows[showDetails]?.createdAt).toDateString()}</OverflowHiddenText>
                                </Box>
                            </Grid>
                            <Grid Item xs={12} sm={4}>
                                <Box display="flex" flexDirection="column">
                                <BgGrey>User web3 Address</BgGrey>
                                <OverflowHiddenText>{rows[showDetails]?.userWeb3Address}</OverflowHiddenText>
                                </Box>
                            </Grid>
                            <Grid Item xs={12} sm={4}>
                                <Box display="flex" flexDirection="column">
                                <ColorGrey>Ref ID</ColorGrey>
                                <Typography><span>{rows[showDetails]?.referenceId}</span></Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box marginTop="20px" marginBottom="20px">
                            <Grid container>
                                <Grid Item xs={12} sm={4}>
                                <ColorGrey>Game Instance ID</ColorGrey>
                                <Typography><span>{rows[showDetails]?.gameInstanceId}</span></Typography>
                                </Grid>
                                <Grid Item xs={12} sm={4}>
                                <ColorGrey>User ID</ColorGrey>
                                <Typography><span>{rows[showDetails]?.gameUserId}</span></Typography>
                                </Grid>
                                <Grid Item xs={12} sm={4}>
                                <ColorGrey>SupaKI Ref ID</ColorGrey>
                                <Typography><span>{rows[showDetails]?.supakiRefId}</span></Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        </BgGrey>

                        <PageTitle >{rows[showDetails]?.methodType?.toLowerCase().indexOf('mint') != -1 ? "Mint" : rows[showDetails]?.methodType.toLowerCase().indexOf('burn') != -1 ? "Burn" : "Transfer"} Details</PageTitle>

                        <BgGrey marginTop="16px" marginBottom="32px" padding="24px">
                        <Grid container>
                            <Grid Item xs={12} sm={4}>
                                <Box display="flex" flexDirection="column">
                                    <ColorGrey>Contract Address</ColorGrey>
                                    <OverflowHiddenText>{rows[showDetails]?.contractAddress}</OverflowHiddenText>
                                </Box>
                            </Grid>
                            <Grid Item xs={12} sm={4}>
                                <Box display="flex" flexDirection="column">
                                <ColorGrey> Token ID</ColorGrey>
                                <Typography><span>{displayMinusWhenUnavailable(rows[showDetails]?.tokenId)}</span></Typography>
                                </Box>
                            </Grid>
                            <Grid Item xs={12} sm={4}>
                                <Box display="flex" flexDirection="column">
                                <ColorGrey> Mint Type</ColorGrey>
                                <Typography><span>{rows[showDetails]?.mintType}</span></Typography>
                                </Box>
                            </Grid>

                            <Grid Item xs={12} sm={4}>
                                <Box display="flex" flexDirection="column">
                                    <ColorGrey>Fiat Fee</ColorGrey>
                                    <OverflowHiddenText>{ displayMinusWhenUnavailable(rows[showDetails]?.fiatFee) } { displayBlankWhenUnavailable(rows[showDetails]?.fiatFeeCurrency) }</OverflowHiddenText>
                                </Box>
                            </Grid>
                            <Grid Item xs={12} sm={4}>
                                <Box display="flex" flexDirection="column">
                                <ColorGrey> Crypto Fee</ColorGrey>
                                <Typography><span>{ displayMinusWhenUnavailable(rows[showDetails]?.cryptoFee) } { displayBlankWhenUnavailable(rows[showDetails]?.cryptoFeeCurrency) }</span></Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        </BgGrey>
                    </DetailedWrapper>
                    }
                </> :
                <Typography align="center" variant="h6">No data available</Typography>
            }
        </>
        
    )
}

const CustomTable = styled(Table)({
    border: '1px solid #E3E3E3',
    borderRadius: '2px',
    '& thead th': {
        fontWeight: 700,
        padding: '8px 16px',
        borderRadius: '2px',
        border: '1px solid #E3E3E3'
    },
    '& tbody td': {
        padding: '12px 16px',
        border: '1px solid #E3E3E3 !important'
    }
})

const ActionButton = styled(Button)({
    fontgame_id: '16px',
    color: '#6D00DB',
})

const PageTitle = styled(Typography)({
    marginTop: '20px',
    marginBottom: '24px',
    fontWeight: "700",
    fontSize: '20px !important'
})

const DetailedButton = styled(Button)({
    color: '#6D00DB'
})

const DetailedWrapper = styled(Box)({
    '& *': {
        fontSize: '14px'
    }
})

const BgGrey = styled(Box)({
    background: '#F9F9F9'
})

const ColorGrey = styled(Typography)({
    color: '#828282',
    fontWeight: 'normal'
})

const SecondaryHeading = styled(Typography)({
    fontSize: '16px',
    color: '#282828',
    fontWeight: 700,
    marginBottom: '16px'
})

const OverflowHiddenText = styled(Typography)({
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: "10px",
    fontWeight: 700
})

const OperationStatus = styled(Typography)({
    fontSize: '14px',
    fontWeight: 500
})

const GoBackButton = styled(Button)({
    padding: 0,
    minWidth: 0,
    marginRight: 16,
    '&:hover': {
        background: 'none'
    }
})

const GoBackText = styled(Typography)({
    fontSize: '14px',
    fontStyle: 700,
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '-0.4px',
    color: '#828282'
})

export default SDKStatistics;