import axios from "axios"
import { BASE_URL } from "../constants/env"
import { useSelector } from "react-redux"


const AuthAxios = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
    }
});

export default AuthAxios;