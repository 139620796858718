// actions.js

import axios from 'axios';
// import jwt from 'jsonwebtoken';
import jwt_decode from 'jwt-decode';
import { BASE_URL } from '../constants/env';

export const login = (email, password) => async (dispatch) => {    
  try {
    const res = await axios.post(`${BASE_URL}/dashboard/v1/validate`, {
        email,
        password
    });
    console.log('login Response');
    console.log(res);

    if (res.status === 200) {
        const token = res.data.data[0].access_token;
        const decodedUserInfo = jwt_decode(token);
        console.log("decodedUserInfo", decodedUserInfo)

        const user = {
            email: decodedUserInfo.email,
            partner_id: decodedUserInfo.sub,
            exp: decodedUserInfo.exp,
            client_id: res.data.data[0].client_id,
            game_instance_ids: res.data.data[0].game_instance_ids
        }

        dispatch({
            type: 'LOGIN_SUCCESS',
            payload: { user, token },
        });   
    }    
  } catch (error) {
    dispatch({
      type: 'LOGIN_FAILURE',
      payload: error,
    });
  }
};

export const logout = () => async (dispatch) => {
  dispatch({ type: 'LOGOUT' });
};