import { Button, Container, Table, Typography, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import { Box, styled } from '@mui/system';
import React from 'react';
import { convertShortenedWeb3Address } from '../utils';
import Pagination from '../components/Pagination';

const Settlements = () => {
    const [showDetails, setShowDetails] = React.useState(false);

    return (
        <PageWrapper>
            {
                showDetails === false ?
                <>    
                    <PageHeader>Settlements</PageHeader>

                    <SettlementsTableWrapper>
                        <SettlementsTable>
                        <TableHead>
                            <TableRow>
                                <TableCell width="19.5%">Mechanism</TableCell>
                                <TableCell width="19.5%">Date</TableCell>
                                <TableCell width="19.5%">Dev Share Type</TableCell>
                                <TableCell width="19.5%">Status</TableCell>
                                <TableCell width="21%">Settlement Amount</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {rows.map((row, key) => ( */}
                                    <TableRow
                                        
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">SK724FTUH764</TableCell>
                                        <TableCell>{new Date().toDateString()}</TableCell>
                                        <TableCell>
                                            Fiat
                                        </TableCell>
                                        <TableCell>
                                            Pending
                                        </TableCell>
                                        <TableCell>
                                            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                                USD 20.00

                                                <ShowDetailsButton onClick={() => setShowDetails(1)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M16.8006 12.0005C16.8006 12.7005 16.5306 13.4005 16.0006 13.9305L9.48062 20.4505C9.19062 20.7405 8.71062 20.7405 8.42063 20.4505C8.13063 20.1605 8.13063 19.6805 8.42063 19.3905L14.9406 12.8705C15.4206 12.3905 15.4206 11.6105 14.9406 11.1305L8.42063 4.6105C8.13063 4.3205 8.13063 3.8405 8.42063 3.5505C8.71062 3.2605 9.19062 3.2605 9.48062 3.5505L16.0006 10.0705C16.5306 10.6005 16.8006 11.3005 16.8006 12.0005Z" fill="#0652DD"/></svg>
                                                </ShowDetailsButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                {/* ))} */}
                            </TableBody>
                        </SettlementsTable>
                    </SettlementsTableWrapper>

                    <Pagination />
                </> :
                <>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <GoBackButton onClick={() => setShowDetails(false)}><svg xmlns="http://www.w3.org/2000/svg" width="9" height="18" viewBox="0 0 9 18" fill="none"><g clipPath="url(#clip0_407_88)"><path d="M7.9993 17.67C7.8093 17.67 7.6193 17.6 7.4693 17.45L0.949297 10.93C-0.110703 9.87 -0.110703 8.13 0.949297 7.07L7.4693 0.55002C7.7593 0.26002 8.2393 0.26002 8.5293 0.55002C8.8193 0.84002 8.8193 1.32002 8.5293 1.61002L2.0093 8.13C1.5293 8.61 1.5293 9.39 2.0093 9.87L8.5293 16.39C8.8193 16.68 8.8193 17.16 8.5293 17.45C8.3793 17.59 8.1893 17.67 7.9993 17.67Z" fill="#828282"/></g><defs><clipPath id="clip0_407_88"><rect width="9" height="18" fill="white"/></clipPath></defs></svg></GoBackButton>
                        <GoBackText>Back</GoBackText>
                    </Box>

                    <Box marginTop="20px" marginBottom="20px" display="flex" flexDirection="row" alignItems="center">
                        <SettlementText>Settlement Details</SettlementText>
                        <GameNameText>Call of Duty Season 4</GameNameText>
                    </Box>

                    <Box display="flex" flexDirection="row">
                        <SettlementDetailsWrapper>
                            <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                                <SettlementDetailsFieldText>Mechanism</SettlementDetailsFieldText>
                                <SettlementDetailsValueText>SP342156722</SettlementDetailsValueText>
                            </Box>

                            <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                                <SettlementDetailsFieldText>Date</SettlementDetailsFieldText>
                                <SettlementDetailsValueText>{new Date().toDateString()}</SettlementDetailsValueText>
                            </Box>

                            <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                                <SettlementDetailsFieldText>Dev Share Type</SettlementDetailsFieldText>
                                <SettlementDetailsValueText>Fiat</SettlementDetailsValueText>
                            </Box>

                            <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                                <SettlementDetailsFieldText>Status</SettlementDetailsFieldText>
                                <SettlementDetailsValueText>Pending</SettlementDetailsValueText>
                            </Box>

                            <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                                <SettlementAmountField>Settlement Amount</SettlementAmountField>
                                <SettlementAmountValue>USD 200.00</SettlementAmountValue>
                            </Box>
                        </SettlementDetailsWrapper>

                    </Box>
                </>
            }
        </PageWrapper>
    )
}

const PageWrapper = styled(Container)({
    padding: '25px 40px'
})

const PageHeader = styled(Typography)({
    color: '#282828',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
})

const SettlementsTableWrapper = styled(`div`)({
    marginTop: '24px'
})

const SettlementsTable = styled(Table)({
    border: '1px solid #E3E3E3',
    borderRadius: '2px',
    '& thead th': {
        fontWeight: 700,
        padding: '8px 16px',
        borderRadius: '2px',
        border: '1px solid #E3E3E3',
        background: '#F9F9F9',
        color: '#828282'
    },
    '& tbody td': {
        padding: '12px 16px',
        border: '1px solid #E3E3E3 !important',
        overflow: 'hidden'
    }
})

const ShowDetailsButton = styled(Button)({
    '&:hover': {
        background: 'none',
        color: 'black'
    }
})


const GoBackButton = styled(Button)({
    padding: 0,
    minWidth: 0,
    marginRight: 16,
    '&:hover': {
        background: 'none'
    }
})

const GoBackText = styled(Typography)({
    fontSize: '14px',
    fontStyle: 700,
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '-0.4px',
    color: '#828282'
})

const SettlementText = styled(Typography)({
    color: '#282828',
    fontSize: '20px',
    fontStyle: 700,
    fontWeight: 600,
    lineHeight: '24px',
    paddingRight: '16px',
    borderRight: '1px solid #828282'
})

const GameNameText = styled(Typography)({
    color: '#828282',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    paddingLeft: '16px'
})

const SettlementDetailsWrapper = styled(Box)({
    display: 'flex',
    width: '725px',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    background: '#F9F9F9'
})

const SettlementDetailsFieldText = styled(Typography)({
    color: '#828282',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
})

const SettlementDetailsValueText = styled(Typography)({
    color: '#282828',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    overflow: 'hidden',
    textOverflow:'ellipsis',
    whiteSpace: 'nowrap'
})

const SettlementAmountField = styled(Typography)({
    color: '#828282',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
})

const SettlementAmountValue = styled(Typography)({
    color: '#282828',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px'
})
export default Settlements;