import { Container, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import React from 'react';

const Pricing = () => {

    return (

        <PageWrapper>
            <Box marginTop="20px" marginBottom="20px" display="flex" flexDirection="row" alignItems="center">
                <PricingDetailsText>Pricing Details</PricingDetailsText>
            </Box>

            <Box display="flex" flexDirection="column">
                <Box marginTop="16px" marginBottom="8px">
                    <PricingSubHeading>User Driven Commerce</PricingSubHeading>
                </Box>
                <PricingDetailsWrapper>
                    <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                        <PricingDetailsFieldText>Platform Fee</PricingDetailsFieldText>
                        <PricingDetailsValueText>10%</PricingDetailsValueText>
                    </Box>

                    <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                        <PricingDetailsFieldText>Creator Fee</PricingDetailsFieldText>
                        <PricingDetailsValueText>50%</PricingDetailsValueText>
                    </Box>

                    <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                        <PricingDetailsFieldText>Settlement Mode</PricingDetailsFieldText>
                        <PricingDetailsValueText>Monthly</PricingDetailsValueText>
                    </Box>
                </PricingDetailsWrapper>

                <Box marginTop="24px" marginBottom="8px" display="flex" flexDirection="row" alignItems="center">
                    <PricingSubHeading>Infrastructure Fee</PricingSubHeading>
                </Box>

                <PricingDetailsWrapper>
                    <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                        <PricingDetailsFieldText>Number of Customers</PricingDetailsFieldText>
                        <PricingDetailsValueText>2,003</PricingDetailsValueText>
                    </Box>

                    <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                        <PricingDetailsFieldText>SupaKI Pricing</PricingDetailsFieldText>
                        <PricingDetailsValueText>30.45 USD</PricingDetailsValueText>
                    </Box>

                    <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                        <PricingDetailsFieldText>Monthly infrastructure Fee</PricingDetailsFieldText>
                        <PricingDetailsValueText>Monthly</PricingDetailsValueText>
                    </Box>
                </PricingDetailsWrapper>

                <Box marginTop="24px" marginBottom="8px" display="flex" flexDirection="row" alignItems="center">
                    <PricingSubHeading>Primary Commerce</PricingSubHeading>
                </Box>

                <PricingDetailsWrapper>
                    <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                        <PricingDetailsFieldText>Platform fee</PricingDetailsFieldText>
                        <PricingDetailsValueText>10%</PricingDetailsValueText>
                    </Box>

                    <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                        <PricingDetailsFieldText>Creator Fee</PricingDetailsFieldText>
                        <PricingDetailsValueText>50%</PricingDetailsValueText>
                    </Box>

                    <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                        <PricingDetailsFieldText>Settlement Mode</PricingDetailsFieldText>
                        <PricingDetailsValueText>Monthly</PricingDetailsValueText>
                    </Box>
                </PricingDetailsWrapper>

                <Box marginTop="24px" marginBottom="8px" display="flex" flexDirection="row" alignItems="center">
                    <PricingSubHeading>Gas Fee for SDK Transactions</PricingSubHeading>
                </Box>

                <PricingDetailsWrapper>
                    <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                        <PricingDetailsFieldText>Game Name</PricingDetailsFieldText>
                        <PricingDetailsValueText>Call of Duty Season 4</PricingDetailsValueText>
                    </Box>

                    <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                        <PricingDetailsFieldText>Gas Fee paid by</PricingDetailsFieldText>
                        <PricingDetailsValueText>Game Dev</PricingDetailsValueText>
                    </Box>

                    <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                        <PricingDetailsFieldText>Gas Fee payment split for Minting</PricingDetailsFieldText>
                        <PricingDetailsValueText>1.20%</PricingDetailsValueText>
                    </Box>

                    <Box display="flex" flexDirection="row" alignItems="space-between" justifyContent="space-between" width="100%">
                        <PricingDetailsFieldText>Gas Fee payment split for Upgrades</PricingDetailsFieldText>
                        <PricingDetailsValueText>2.45%</PricingDetailsValueText>
                    </Box>
                </PricingDetailsWrapper>
            </Box>
        </PageWrapper>
    )
}

const PageWrapper = styled(Container)({
    padding: '25px 40px'
})

const PricingSubHeading = styled(Typography)({
    color: '#282828',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
})

const PricingDetailsText = styled(Typography)({
    color: '#282828',
    fontSize: '20px',
    fontStyle: 700,
    fontWeight: 600,
    lineHeight: '24px',
    paddingRight: '16px'
})

const PricingDetailsWrapper = styled(Box)({
    display: 'flex',
    width: '725px',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    background: '#F9F9F9'
})

const PricingDetailsFieldText = styled(Typography)({
    color: '#828282',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
})

const PricingDetailsValueText = styled(Typography)({
    color: '#282828',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    overflow: 'hidden',
    textOverflow:'ellipsis',
    whiteSpace: 'nowrap'
})

export default Pricing;