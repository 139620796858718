const initialState = {
    auth: {
      user: JSON.parse(localStorage.getItem('user_info') || "{}"),
      token: localStorage.getItem('access_token') || null,
      isAuthenticated: false,
      error: null
    },
  };
  
  export const rootReducer = (state = initialState, action) => {
    console.log('rootReducer', state, action.type)
    switch (action.type) {
      case 'LOGIN_SUCCESS':
        return {
          ...state,
          auth: {
            user: action.payload.user,
            token: action.payload.token,
            isAuthenticated: true,
            error: null,
          },
        };
      case 'LOGIN_FAILURE':
        return {
          ...state,
          auth: {
            user: null,
            token: null,
            isAuthenticated: false,
            error: action.payload,
          },
        };
      case 'LOGOUT':
        return {
          ...state,
          auth: {
            user: null,
            token: null,
            isAuthenticated: false,
            error: null,
          },
        };
      default:
        return state;
    }
  };