import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Container, Typography, Button, Box } from '@mui/material';
import React from 'react'

import { useEffect } from 'react';
import axios from 'axios'
import { useState } from 'react';
import { BASE_URL } from '../constants/env';
import { useSelector } from 'react-redux';
import { styled } from '@mui/system';

const SDKList = () => {
    const [rows, setRows] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const access_token = useSelector(state => state.auth.token);
    
    useEffect(() => {
        if (!isLoading) {
            setLoading(true)
            axios.get(`${BASE_URL}/dashboard/v1/sdk-lists`, {
                headers: {
                    'Authorization': 'Bearer ' + access_token, 
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                setLoading(false)
                if (response.status == 200) {
                    console.log(response)
                    setRows(response.data.data[0].sdk_lists);
                }
            }).catch(err => {
                setLoading(false)
            })
        }
    }, [])
    // const classes = useStyles()

    const downloadFile = (url, name) => {
      
        fetch(url)
          .then(response => {
            return response.blob();
          })
          .then(blob => {
            const element = document.createElement('a');
            element.setAttribute('href', URL.createObjectURL(blob));
            element.setAttribute('download', name);
      
            element.style.display = 'none';
            document.body.appendChild(element);
      
            element.click();
      
            document.body.removeChild(element);
          });
      }
    return (
        <>
            {

            isLoading ?
            <Typography align="center" variant="h6">Loading...</Typography> :
            rows.length > 0 ?
            <Container>
                <PageTitle>SDK Lists</PageTitle>
                <TableContainer>
                    <SdkTable sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>SDK Name</TableCell>
                                <TableCell>Version</TableCell>
                                <TableCell>Created Date</TableCell>
                                <TableCell>Size (Mb)</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, key) => (
                                <TableRow
                                    key={key}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{row.name}</TableCell>
                                    <TableCell>{row.version}</TableCell>
                                    <TableCell>{new Date(row.createdAt).toDateString()}</TableCell>
                                    <TableCell>{row.size}</TableCell>
                                    <TableCell>
                                        <ActionButton onClick = {() => {
                                            window.open(row.releaseNotesUrl);
                                        }}>Release Notes</ActionButton>
                                        <ActionButton onClick = {() => downloadFile(row.sdkSourceUrl, row.name)}>Download</ActionButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </SdkTable>
                </TableContainer>

                {/* <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop="20px">
                    <Typography>Showing 1 to 10 of 98 entries</Typography>
                    <Pagination />
                </Box> */}
            </Container> :
            <Typography align="center" variant="h6">No data is available</Typography>
            }
        </>
    )
}

const SdkTable = styled(Table)({
    border: '1px solid #E3E3E3',
    borderRadius: '2px',
    '& thead th': {
        fontWeight: 700,
        padding: '8px 16px',
        borderRadius: '2px',
        border: '1px solid #E3E3E3'
    },
    '& tbody td': {
        padding: '12px 16px',
        border: '1px solid #E3E3E3 !important'
    }
})

const ActionButton = styled(Button)({
    fontSize: '16px',
    color: '#6D00DB',
})

const PageTitle = styled(Typography)({
    marginBottom: '24px',
    fontWeight: "700"
})

export default SDKList;