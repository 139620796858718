import React, {useState,useEffect} from "react";

import { Box, Container, Grid, IconButton, Typography } from '@mui/material';
// import { Twitter, Telegram, Delete, Visibility } from '@material-ui/icons';
import { styled } from '@mui/system';

import { BASE_URL } from "../constants/env";
import { useSelector } from "react-redux";
import axios from "axios";

import { Visibility } from "../components/Icon";
import LazyImage from "../components/LazyImage";


const GameDetails = () => {
    const auth = useSelector(state => state.auth)
    const [showDetails, setShowDetails] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);

    const detailed = {
        title: "Fantasy Game",
        verified: true,
        video_urls: ["http://youtube/01.mp4", "http://youtube/02.mp4", "http://youtube/03.mp4"],
        image_urls: ["http://scrnshare.com/01.png", "http://scrnshare.com/02.png", "http://scrnshare.com/03.png"],
        genre: "Fighting",
        payment_method: "Fiat",
        description: "This game support real battle environment",
        platform_info: "Polygon",
        downloads: "5M +",
        visible: true,
        cover_pic_url: "/pics/Thumbnail.png",
        thumbnail_url: "/pics/icon.png",
        ratings: "4.2",
        number_of_users: "3.5K +",
        number_of_items: "100K +",
        release_status: "Alpha released",
        age_restriction: "13",
        game_type: "Fighting 3D",
        telegram_url: "https://t.me/crypto_unicorn",
        discord_url: "https://discord.com/crypto_unicorn",
        twitter_url: "https://twitter.com/crypto_unicorn",
        share_url: "https://shared.app/crypto_unicorn",
        about_developer: "Adam Lim",
        package_name: "Unreal Engine",
        media_links: ["https://media.links/01.mp4", "https://media.links/02.mp4", "https://media.links/03.mp4"],
        app_size: "45.4 GB",
        collect_items_description: "This is description for collect",
        collect_game_coins_description: "This is description for collect coins",
        download_url: "https://play.store.google/crypto_unicorn.apk"
    }

    useEffect(() => {
        if (!isLoading) {
            setIsLoading(true)

            axios.get(`${BASE_URL}/dashboard/v1/get-game-details`,{
                headers: {
                    'Authorization': 'Bearer ' + auth.token, 
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                setIsLoading(false);
                setRows(res?.data);
                console.log(res?.data);
            }).catch(err => {
                setIsLoading(false);
                console.log(err)
            })
        }
    }, [])

    return (
        <>
        {
            isLoading ?
            <Typography align="center" variant="h6">Loading...</Typography> :
            rows.length > 0 ?
                <>
                {
                showDetails === false ?
                <Container>
                    <Typography variant="h6">
                        Game Details    
                    </Typography>


                    <Box padding={2} width="100%">
                        <Grid container spacing={8}>
                            {
                                rows.map((row, key) => 
                                <Grid item md={4} xs={12} key={key}>
                                    <CardRoot>
                                        <Box style={{position: "relative"}} display="flex" flexDirection="column">
                                        {/* <LazyImage  src={row.coverPicUrl} alt="Cover Picture"/> */}
                                            <CardThumbnailImage  src={row.coverPicUrl} alt="Cover Picture"/>
                                            <CardIconImage src={row.thumbnailUrl} alt="Thumbnail Picture"/>
                                        </Box>
                                        <GameInfoWrapper>
                                            <CardGameTitle>{row.title}</CardGameTitle>
                                            <ChainInfoWrapper>
                                                <img  src="/pics/polygon.png" />
                                                <span>Polygon</span>
                                            </ChainInfoWrapper>
                                        </GameInfoWrapper>

                                        <Overlay className="overlay">
                                            <IconButton onClick={() => {
                                                console.log(`Showing Details ${key}`)
                                                setShowDetails(key)
                                            }}> 
                                                <Visibility fill="white" width="24px" height="24px" />
                                                {/* <Visibility style={{color: "white"}} /> */}
                                            </IconButton>
                                            <IconButton> 
                                                {/* <Delete style={{color: "#ff4b4b"}} /> */}
                                            </IconButton>
                                        </Overlay>
                                    </CardRoot>
                                </Grid>)
                            }
                        </Grid>
                    </Box>
                </Container>
                :
                <Container>
                    <GameDetailsWrapper>
                        <GameDetailsCoverPicture style={{backgroundImage: `url(${rows[showDetails]?.coverPicUrl})`}}>
                            <GameDetailsSocialBox display="flex" flexDirection="row">
                                <IconButton onClick={() => {window.location.href = rows[showDetails]?.twitterUrl}} >
                                    {/* <Twitter className="twitter" /> */}
                                </IconButton>
                                <IconButton onClick={() => {window.location.href = rows[showDetails]?.discordUrl}}>
                                    {/* <Twitter /> */}
                                </IconButton>
                                <IconButton onClick={() => {window.location.href = rows[showDetails]?.telegramUrl}}>
                                    {/* <Telegram className="telegram" /> */}
                                </IconButton>
                            </GameDetailsSocialBox>
                        </GameDetailsCoverPicture>
                        <ThumbnailIconWrapper>
                            <img src={rows[showDetails]?.thumbnailUrl} />
                        </ThumbnailIconWrapper>
                        <Box p={5} mt={5}>
                            <Typography variant="h6">
                                Title: {rows[showDetails]?.title} <i>({rows[showDetails]?.packageName})</i> - {rows[showDetails]?.releaseStatus}
                            </Typography>

                            <GameDetailsDescription>
                                Description: {rows[showDetails]?.description}
                            </GameDetailsDescription>

                            <Grid container>
                                <Grid item sm={4}>
                                    <GameDetailsItemStatus display="flex" flexDirection="column" alignItems="center" >
                                        <p>Downloads</p>
                                        {rows[showDetails]?.downloads}
                                    </GameDetailsItemStatus>
                                </Grid>
                                <Grid item sm={4}>
                                    <GameDetailsItemStatus display="flex" flexDirection="column" alignItems="center">
                                        <p>Ratings</p>
                                        {rows[showDetails]?.ratings}
                                    </GameDetailsItemStatus>
                                </Grid>
                                <Grid item sm={4}>
                                    <GameDetailsItemStatus display="flex" flexDirection="column" alignItems="center">
                                        <p>Genre</p>
                                        {rows[showDetails]?.genre}
                                    </GameDetailsItemStatus>
                                </Grid>
                                <Grid item sm={4}>
                                    <GameDetailsItemStatus display="flex" flexDirection="column" alignItems="center">
                                        <p>Total Users</p>
                                        {rows[showDetails]?.numOfUsers}
                                    </GameDetailsItemStatus>
                                </Grid>
                                <Grid item sm={4}>
                                    <GameDetailsItemStatus display="flex" flexDirection="column" alignItems="center">
                                        <p>Total Items</p>
                                        {rows[showDetails]?.numOfItems}
                                    </GameDetailsItemStatus>
                                </Grid>
                                <Grid item sm={4}>
                                    <GameDetailsItemStatus display="flex" flexDirection="column" alignItems="center">
                                        <p>Age Restriction</p>
                                        {rows[showDetails]?.ageRestriction}
                                    </GameDetailsItemStatus>
                                </Grid>
                            </Grid>
                        </Box>
                    </GameDetailsWrapper>
                </Container>
                }
                </> :
            <Typography align="center" variant="h6">No data available...</Typography> 
        }
        </>
    )
}

const CardRoot = styled(Box)({
    borderRadius: '20px',
    border: '1px solid grey',
    position: "relative",
    "&:hover .overlay": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    }
})

const Overlay = styled('div')({
    position: "absolute",
    display: "none",
    background: "rgba(0,0,0,.5)",
    borderRadius: "20px",
    boxSizing: "border-box",
    padding: "10px",
    width: "100%",
    height: "100%",
    top: 0
})

const CardThumbnailImage = styled('img')({
    width: "100%",
    height: "144px",
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px'
})

const CardIconImage = styled('img')({
    width: "50px",
    borderRadius: "50%",
    position: "absolute",
    bottom: -25,
    left: 5,
    padding: 0,
    boxShadow: "inset 0px 0px 0px 20px white"
    // transform: "translate(0px, -20px)"
})

const CardGameTitle = styled('p')({
    paddingLeft: 10,
    fontSize: 16,
})

const GameInfoWrapper = styled(Box)({
    position: "relative",
    marginTop: "30px",
    paddingLeft: "5px",
})

const ChainInfoWrapper = styled(Box)({
    position: "absolute",
    paddingRight: "5px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
        width: "16px",
        aspectRatio: "1/1",
        marginRight: "10px"
    },
    "& span": {
        fontSize: 13,
        fontWeight: 400,
        color: "grey"
    },
    top: -20,
    right: 0
})

const DeleteButton = styled('svg')({
    color: "#ff4b4b"
})

const VisibilityButton = styled('svg')({
    color: "white"
})

const GameDetailsWrapper = styled(Box)({
    width: "100%",
    borderTopRightRadius: "40px",
    borderTopLeftRadius: "40px"
})

const GameDetailsCoverPicture = styled('div')({
    height: "280px",
    backgroundPosition: "center",
    borderTopRightRadius: "40px",
    borderTopLeftRadius: "40px",
    position: "relative"
})

const ThumbnailIconWrapper = styled(Box)({
    position: "relative",
        "& img": {
            width: "80px",
            position: "absolute",
            top: -20,
            left: 20,
            boxShadow: "inset 0px 0px 0 40px #e8e8e8",
            borderRadius: "50%"
        }
})

const GameDetailsItemStatus = styled(Box)({
    background: "#e3e3e3",
    paddingBottom: "10px",
    color: "#7a7a7a",
    "&>p": {
        color: "black"
    }
})

const GameDetailsDescription = styled('p')({
    fontSize: 13
})

const GameDetailsSocialBox = styled(Box)({
    position: "absolute",
    right: 10,
    bottom: -60,
    "& .twitter": {
        color: "#1c9cea"
    },
    "& .telegram": {
        color: "#229bd5"
    }
})

export default GameDetails