import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from './../redux/action';
import { useEffect } from 'react';
import { redirect, useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Container, Input, InputAdornment, Link } from '@mui/material';
import { styled } from '@mui/system';
import { Toaster, toast } from 'react-hot-toast';


const classes = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
  },
};

const Wrapper = styled('div')({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: 'radial-gradient(circle, #5c0067 0%, #00d4ff 100%)'
})

const LoginBox = styled(Box)({
  background: 'white',
  height: '500px',
  width: 'fit-content',
  padding: '10px 60px',
  borderRadius: '5px'
})

const LoginButton = styled(Button)({
  borderRadius: '50px',
  background: '#6D00DB',
  '&:hover': {
    background: '#501989'
  }
})

function LoginPage() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const error = useSelector(state => state.auth.error);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("isAuthenticated", isAuthenticated)
    if (isAuthenticated) {
      navigate('/sdk-list')
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated && error !== null) {
      if (error?.response?.data?.message) {
        toast(error?.response?.data?.message)
      } else {
        toast(error?.message)
      }
    }
  }, [error])

  const handleSubmit = e => {
    e.preventDefault();

    if (email == "") {
      toast("Email should not be empty");
    } else if (password == "") {
      toast("Password should not be empty");
    } else if (password.length < 6) {
      toast("Password should be longer than 6 letters");
    } else {
      dispatch(login(email, password));
    }
  };

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);

  return (
    <Wrapper>
      <LoginBox display="flex" alignItems="center" justifyContent="center">
        <form style={classes.root} onSubmit={handleSubmit}>
          <img src="/assets/logo.png" style={{ marginBottom: '70px' }} />
          <TextField
            label={"Email"}
            variant="standard"
            type="email"
            value={email}
            onChange={handleEmailChange}
          />
          <TextField
            label="Password"
            variant="standard"
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />

          <LoginButton variant="contained" color="primary" type="submit" style={{ marginTop: 30 }}>
            Login
          </LoginButton>

          <Link fontSize={13}>Not have an account yet?</Link>
        </form>
      </LoginBox>

      <Toaster />
    </Wrapper>
  );
}

export default LoginPage;
